<template>
  <div id="member_pay" class="container--fluid pt-3">
    <v-card v-if="showListMemPay">
      <v-card-title>
        <v-flex>
          <v-card color="yellow">
            <v-card-text>
              <v-container fluid grid-list-md ma-0 pa-0>
                <v-layout wrap>
                  <v-flex lg2 md12>
                    <v-select
                      label="จังหวัด"
                      v-model="dataFrm.pro_id"
                      required
                      :items="this.$store.getters.getlistProvince"
                      item-value="ProID"
                      item-text="ProName"
                    ></v-select>
                  </v-flex>
                  <v-flex lg2 md12>
                    <v-select
                      label="อำเภอ"
                      v-model="dataFrm.dis_id"
                      required
                      :items="
                        this.$store.getters.getlistDistrict({
                          ProID: this.dataFrm.pro_id,
                        })
                      "
                      item-text="DisName"
                      item-value="DisID"
                    ></v-select>
                  </v-flex>
                  <v-flex lg2 md12>
                    <v-select
                      label="ตำบล"
                      v-model="dataFrm.loc_id"
                      required
                      :items="
                        this.$store.getters.getlistLocality({
                          ProID: this.dataFrm.pro_id,
                          DisID: this.dataFrm.dis_id,
                        })
                      "
                      item-text="LocName"
                      item-value="LocID"
                    ></v-select>
                  </v-flex>
                  <v-flex lg3 md12>
                    <v-select
                      label="หมู่บ้าน"
                      v-model="dataFrm.mem_villate"
                      required
                      :items="
                        this.$store.getters.getlistVillate({
                          ProID: this.dataFrm.pro_id,
                          DisID: this.dataFrm.dis_id,
                          LocID: this.dataFrm.loc_id,
                        })
                      "
                      item-text="vil_name"
                      item-value="vil_id"
                    ></v-select>
                  </v-flex>
                  <v-flex
                    lg3
                    md12
                    v-if="dataFrm.mem_head_line != '' && dataFrm.sav_period != ''"
                    ><v-btn color="info" class="mr-2" large @click="loadData"
                      ><v-icon>mdi-file-find</v-icon> แสดงข้อมูล</v-btn
                    >
                    <v-btn color="success" large @click="saveData" v-if="hideBTNCheckAll"
                      ><v-icon>mdi-check-all</v-icon>ยืนยันการชำระ</v-btn
                    >
                  </v-flex>
                  <h2 class="red--text text-jusify" v-else>กรุณาเลือกข้อมูลให้ถูกต้อง</h2>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-card-title>
      <v-card-title class="dark--text">
        รายงานข้อมูลการชำระเงิน
        <v-spacer></v-spacer>
        <div class="text-wrap text-sm-body-1 font-main" v-html="statisData"></div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหาข้อมูล"
          single-line
          hide-details
        ></v-text-field
        ><v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="gray" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="desserts" :search="search" single-select>
          <template v-slot:[`header.data-table-select`]>
            <v-switch
              inset
              dense
              pa-0
              ma-0
              v-model="checkall"
              v-if="hideBTNCheckAll"
            ></v-switch>
          </template>
          <template v-slot:[`item.data-table-select`]="{ item }">
            <v-switch
              inset
              dense
              pa-0
              ma-0
              v-model="item.sav_state"
              :value="item.sav_state"
              @change="
                item.sav_state == true
                  ? (item.sav_money_member_pay = item.sav_money)
                  : (item.sav_money_member_pay = 0)
              "
              v-if="item.sav_status == '0'"
            ></v-switch>
          </template>
          <template v-slot:[`item.sav_money_member_pay1`]="{ item }">
            {{ item.sav_money_member_pay }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "member_pay",
  data() {
    return {
      search: "",
      dataFrm: {
        pro_id: "50",
        dis_id: "10",
        loc_id: "01",
        mem_villate: "",
      },
      headers: [
        { text: "รหัสสมาชิก", value: "mem_id" },
        { text: "ชื่อ-นามสกุล", value: "mem_fullname" },
        { text: "วันที่สมัคร", value: "mem_regis_date_th" },
        { text: "เสียชีวิต", value: "count_mem_lose" },
        { text: "ยอดที่ต้องชำระ", value: "sav_money" },
        { text: "ชำระแล้ว", value: "sav_money_member_pay" },
        {
          text: "สถานะ",
          value: "data-table-select",
          sortable: false,
        },
      ],
      desserts: [],
      checkall: null,
      showListMemPay: false,
      hideBTNCheckAll: false,
    };
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.desserts);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "member_pay.xlsx");
    },
    ready() {
      this.loadData();
      // window.$.getJSON(
      //   this.$store.state.pathAPI + "DB/checkPeriod",
      //   (param) => {
      //     if (param.length > 0) {
      //  param;
      //     } else {
      //       window.$.getJSON(
      //         this.$store.state.pathAPI + "DB/getCouMemLoseNotPeriod",
      //         (param1) => {
      //           window.Swal.fire({
      //             title: "ไม่พบงวดที่ต้องการรับชำระ",
      //             html:
      //               "ยอดผู้เสียชีวิต จำนวน " +
      //               param1[0].couLoseNotPeriod +
      //               " ราย<br>ต้องการสร้างวดรับชำระหรือไม่?",
      //             icon: "question",
      //             showCancelButton: true,
      //             focusConfirm: true,
      //           }).then((result) => {
      //             if (result.value) {
      //               window.$.post(
      //                 this.$store.state.pathAPI +
      //                   "member_keepmoney/process_keepmoney",
      //                 (param2) => {
      //                   // window.Swal.fire(param2.title, param2.html.param2.icon);
      //                   param2;
      //                   this.loadData();
      //                 },
      //                 "json"
      //               );
      //             }
      //           });
      //         }
      //       );
      //     }
      //   }
      // );
      //
    },

    loadData() {
      this.showListMemPay = true;
      this.checkall = null;
      this.desserts = [];
      window.$.post(
        this.$store.state.pathAPI + "member_keepmoney/list_Member_Pay",
        this.dataFrm,
        (param) => {
          this.desserts = param;
          this.hideBTNCheckAll = param[0]?.sav_status == "0" ? true : false;
        },
        "json"
      );
    },
    async saveData() {
      window.Swal.fire({
        title: "ยืนยันข้อมูล",
        html:
          "เมื่อทำการยืนยันข้อมูลการชำระ<br>ท่านจะไม่สามารถกลับมาแก้ข้อมูลได้อีก<br>ยืนยันข้อมูล?",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          var vData = [];
          this.desserts.forEach((ele) => {
            vData.push({
              sav_period: ele.sav_period,
              mem_id: ele.mem_id,
              status_pay: ele.sav_state,
              sav_money_member_pay: ele.sav_money_member_pay,
            });
          });
          window.$.post(
            this.$store.state.pathAPI + "member_keepmoney/Update_KeepMoney",
            {
              dataFrm: this.dataFrm,
              data: vData,
              ad_id: this.$store.getters.checkLogin.mem_id,
            },
            (param) => {
              window.Swal.fire(param.title, param.html, param.icon);
              this.loadData();
            },
            "json"
          );
        }
      });
    },
  },
  mounted() {
    this.ready();
  },
  computed: {
    statisData() {
      return (
        "สมาชิก " +
        this.desserts.length +
        "  ราย (<span class='red--text'>ยอดรวม " +
        this.$store.getters.number_format(
          this.desserts
            .map((el) => el.sav_money)
            .reduce((total, curren) => {
              return parseInt(total) + parseInt(curren);
            }, 0)
        ) +
        " บาท</span>,ชำระแล้ว " +
        this.desserts.filter((val) => val.sav_state == "1").length +
        " ราย <span class='blue--text'>หัก 3% คิดเป็น " +
        this.$store.getters.number_format(
          this.desserts
            .map((el) => {
              return el.sav_state == "1" ? Math.floor(el.sav_money*3/100) : 0;
            })
            .reduce((total, curren) => {
              return parseInt(total) + parseInt(curren);
            }, 0)
        ) +
        " บาท </span><span class='green--text'>ยอดรับ " +
        this.$store.getters.number_format(
          this.desserts
            .map((el) => {
              return el.sav_state == "1" ? el.sav_money-Math.floor(el.sav_money*3/100) : 0;
            })
            .reduce((total, curren) => {
              return parseInt(total) + parseInt(curren);
            }, 0)
        ) +
        " บาท</span>,<span class='orange--text'> ค้าง " +
        this.desserts.filter((val) => val.sav_state == "0" || val.sav_state == null)
          .length +
        " ราย คิดเป็น " +
        this.$store.getters.number_format(
          this.desserts
            .map((val) => {
              return val.sav_state == "0" || val.sav_state == null ? val.sav_money : 0;
            })
            .reduce((total, curren) => {
              return parseInt(total) + parseInt(curren);
            }, 0)
        ) +
        " บาท</span>)"
      );
    },
  },
  watch: {
    checkall() {
      let stat = 0;
      if (this.checkall == true) {
        stat = 1;
      }
      this.desserts.forEach((ele) => {
        ele.sav_state = stat;
        if (this.checkall == true) {
          ele.sav_money_member_pay = ele.sav_money;
        } else {
          ele.sav_money_member_pay = 0;
        }
      });
    },
  },
};
</script>

<style>
.v-application .text-sm-body-1 {
  font-family: "Niramit", sans-serif !important;
}
</style>
